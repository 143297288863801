import React from 'react'

import logo from '../assets/images/logo.svg';

const Header = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img src={logo} alt="" /></span>
        <p>Design AI with Visualization<br /></p>
        <p>AI is a black box, even for AI researchers and developers.
           Machine understands numbers, but human understands pictures.
           Supplai is making people exploring AI models, like CNN, easier
           and intuitively through visualization.</p>
    </header>
)

export default Header
